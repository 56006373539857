import React, { useEffect, useState } from "react";
import axios from "axios";

export default function ConfirmReserva(props) {
    const [text, setText] = useState(undefined);
    const sendToken = async () => {
        try {
            const response = await axios.get(
                "/confirm/reserva/" + props.match.params.token
            );

            setText(response.data);
        } catch (error) {
            // window.location.href = "/";
        }
    };

    useEffect(() => {
        sendToken();
        document.querySelector("title").innerText =
            "Reservas Coworking | CASA SEAT";
    }, []);

    if (!text) {
        return "Cargando ...";
    }

    return <>{text}</>;
}
