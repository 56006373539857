import React, { useEffect, useState } from "react";
import { Inputs } from "./Inputs";
import M from "materialize-css";
import "./css/Form.css";
import { GroupProvider } from "./GroupContext";
import { initiateInstaces } from "./instances";

export default function Form({
    data,
    onChange = (e) => {},
    onSubmit = (e) => {},
    onBlur = (e) => {},
    submitText = "Enviar",
    controlled = true,
    errorObj = {},
    id = "formBuilded",
    withSubmitButton = true,
}) {
    const [formData, setFormData] = useState(data);
    useEffect(() => {
        setFormData(data);
    }, [data]);

    const modifyGroup = (group) => {
        let newData = {
            ...formData,
            ...group,
        };
        setFormData({
            ...newData,
        });
        onChange(newData);
    };

    const onChangeForm = (e) => {
        const newData = { ...formData };

        setFormData({
            ...newData,
        });
        onChange(newData);
    };

    const onSubmitForm = async (e) => {
        e.preventDefault();
        var selects = document.querySelectorAll("#" + id + " select.required");
        let error = false;
        selects.forEach(async (select) => {
            // @ts-ignore
            if (select.value == "") {
                error = true;
                await prevElementMaterializeSelect(select, "input");
                M.toast({ html: "Falta un campo requerido" });
                return false;
            }
        });
        if (error === false) {
            onSubmit(e);
        }
    };

    const prevElementMaterializeSelect = async (e, type) => {
        if (e.previousSibling.tagName.toLowerCase() === type.toLowerCase()) {
            e.previousSibling.focus();
            e.previousSibling.click();
        } else {
            prevElementMaterializeSelect(e.previousSibling, type);
        }
    };

    const findName = async (name, object, value) => {
        const finded = Object.values(object).filter((e) => e.name == name);

        if (finded.length > 0) {
            return finded;
        }

        const groups = Object.values(object).filter((e) => e.type == "group");

        if (groups.length === 0) {
            return false;
        }

        let newSearch = false;

        groups.forEach(async (e) => {
            newSearch = await findName(name, e.childs, value);

            if (newSearch) {
                newSearch[0].value = value;
                console.log(formData);
            }
        });

        return newSearch;
    };

    const changeFile = async (name, base64) => {
        console.log(name, base64);
        const fileObj = await findName(name, formData, base64);
    };

    useEffect(() => {
        initiateInstaces();
    }, [formData]);

    if (Object.keys(formData).length === 0) {
        return <p>Loading...</p>;
    }

    return (
        <GroupProvider
            value={{
                modifyGroup: modifyGroup,
                changeFile: changeFile,
                onChangeForm,
            }}
        >
            <form
                className="row formBuilded"
                onChange={onChangeForm}
                onSubmit={onSubmitForm}
                onBlur={onBlur}
                id={id}
            >
                <div className="row form-error">
                    <div className="col s12">
                        <ul>
                            {Object.entries(errorObj).map(([key, value]) => (
                                <li className="red white-text" key={key}>
                                    {key + " : " + value}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                {Object.entries(formData).map(([key, value]) =>
                    Inputs[value.type ?? "text"](key, value)
                )}
                {withSubmitButton && (
                    <div className="row form-submit">
                        <div className="input-field col s12">
                            <button
                                className="btn btn-primary bt"
                                type="submit"
                            >
                                {submitText}
                            </button>
                        </div>
                    </div>
                )}
            </form>
        </GroupProvider>
    );
}
