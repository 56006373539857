import axios from "axios";
import React, { useEffect, useState } from "react";
import Form from "../Formbuilder/Form";
import M from "materialize-css";

var horas = [];

const dynamic_dataLayer = window.dynamic_dataLayer;

const SITE_KEY = "6LfBvgceAAAAANH217N-USau34vAyZvxOn94G579";

export default function NetworkingForm(props) {
    const [data, setData] = useState(undefined);

    const fetchData = async () => {
        setData(undefined);
        const response = await axios.post("/form/build", {
            form: "Networking",
            action: "create",
        });

        horas.push(
            response.data["Fecha reserva"].childs["fecha_inicio"].value ?? "",
            response.data["Fecha reserva"].childs["hora_entrada"].value ?? ""
        );

        setData(response.data);
    };

    const getDisponibles = async () => {
        const response = await axios.post("/form/build", {
            form: "Networking",
            action: "puestosInput",
            parameters: data,
        });

        if (response.data.error) {
            M.toast({ html: response.data.error, classes: "red white-text" });
        } else {
            setData({
                ...data,
                ...response.data,
            });
        }
    };

    const createNetworking = async (token) => {
        let button = document.querySelector('[type="submit"]');
        button.setAttribute("disabled", true);
        try {
            const response = await axios.post(
                "/networking/" + (data["id*"] ? "edit" : "create"),
                { ...data, "g-recaptcha-response": token }
            );

            if (response.data.error) {
                M.toast({
                    html: response.data.error,
                    classes: "red white-text",
                });
                dynamic_dataLayer.push("errorForm", {
                    eventName: "lastStep",
                    kpiForm: "reserveCoworking",
                    errorType: "No Disponibilidad",
                });
            } else {
                dynamic_dataLayer.push("newForm", {
                    eventName: "lastStep",
                    kpiForm: "reserveCoworking",
                });
                window.location.href =
                    "/coworking/registred/" + response.data.hash;
            }
        } catch (error) {
            dynamic_dataLayer.push("errorForm", {
                eventName: "lastStep",
                kpiForm: "reserveCoworking",
                errorType: error.response?.status ?? "SERVER",
            });
        }
        button.removeAttribute("disabled");
    };

    const handleChange = async (e) => {
        if (
            horas[0] !== e["Fecha reserva"].childs["fecha_inicio"].value ||
            horas[1] !== (e["Fecha reserva"].childs["hora_entrada"].value ?? "")
        ) {
            getDisponibles();
        }

        horas[0] = e["Fecha reserva"].childs["fecha_inicio"].value;
        horas[1] = e["Fecha reserva"].childs["hora_entrada"].value ?? "";
        setData(e);
    };

    const handleSubmit = async () => {
        window.grecaptcha.ready(() => {
            window.grecaptcha
                .execute(SITE_KEY, { action: "submit" })
                .then(async (token) => {
                    await createNetworking(token);
                });
        });
    };

    useEffect(() => {
        fetchData();
        const loadScriptByURL = (id, url, callback) => {
            const isScriptExist = document.getElementById(id);

            if (!isScriptExist) {
                var script = document.createElement("script");
                script.type = "text/javascript";
                script.src = url;
                script.id = id;
                script.onload = function () {
                    if (callback) callback();
                };
                document.body.appendChild(script);
            }

            if (isScriptExist && callback) callback();
        };

        loadScriptByURL(
            "recaptcha-key",
            "https://www.google.com/recaptcha/api.js?render=" + SITE_KEY,
            null
        );
    }, []);

    if (!data) {
        return <p>Loading...</p>;
    }
    return (
        <Form
            data={data}
            onChange={handleChange}
            onSubmit={handleSubmit}
            submitText="Reservar Coworking "
        ></Form>
    );
}
