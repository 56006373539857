import React from "react";
import { Link } from "react-router-dom";
import Logo from "../img/theme/logo-cs-white.svg";

export default function Header(props) {
    return (
        <div className="header">
            <section className="header-wrapper clearfix">
                <div className="header-content">
                    <div className="header-brand-logo">
                        <Link to="/">
                            <div className="logo">
                                <img src={Logo} alt="" />
                            </div>
                        </Link>
                    </div>
                    <div className="header-menu"></div>
                    <div className="header-tools"></div>
                </div>
            </section>
        </div>
    );
}
