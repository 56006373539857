import React from "react";
import M from "materialize-css";
import moment from "moment";

var actualElement = undefined;

const nextElementHourInput = (e, type) => {
    if (e.nextElementSibling.tagName.toLowerCase() === type.toLowerCase()) {
        var el = e.nextElementSibling;
        var value = el.value.split(":");
        forceChange(
            el,
            moment(moment().hour(value[0]).minute(value[1])).format("hh:mm a")
        );
    } else {
        nextElementHourInput(e.nextElementSibling, type);
    }
};

export const forceChange = (el, value) => {
    var nativeInputValueSetter = Object.getOwnPropertyDescriptor(
        window.HTMLInputElement.prototype,
        "value"
    ).set;
    nativeInputValueSetter.call(el, value);

    var ev2 = new Event("input", { bubbles: true });
    el.dispatchEvent(ev2);
};

export const TooltipInstance = (selector, obj = {}) => {
    var tooltips = document.querySelectorAll(selector);
    M.Tooltip.init(tooltips, obj);
};
export const FormSelectInstance = (
    selector,
    obj = {
        dropdownOptions: {
            coverTrigger: false,
        },
    }
) => {
    var selects = document.querySelectorAll(selector);
    M.FormSelect.init(selects, {
        ...obj,
    });
};

export const DatepickerInstace = (
    selector,
    obj = {
        autoClose: true,
        format: "yyyy-mm-dd",
    }
) => {
    var datepicker = document.querySelectorAll(selector);
    M.Datepicker.init(datepicker, {
        ...obj,
        i18n: {
            months: [
                "Enero",
                "Febrero",
                "Marzo",
                "Abril",
                "Mayo",
                "Junio",
                "Julio",
                "Agosto",
                "Septiembre",
                "Octubre",
                "Noviembre",
                "Diciembre",
            ],
            monthsShort: [
                "Ene",
                "Feb",
                "Mar",
                "Abr",
                "May",
                "Jun",
                "Jul",
                "Ago",
                "Sep",
                "Oct",
                "Nov",
                "Dic",
            ],
            weekdays: [
                "Domingo",
                "Lunes",
                "Martes",
                "Miercoles",
                "Jueves",
                "Viernes",
                "Sabado",
            ],
            weekdaysShort: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
            weekdaysAbbrev: ["D", "L", "M", "X", "J", "V", "S"],
        },
        onDraw: (e) => {
            actualElement = e.el;
        },
        onSelect: (date) => {
            const formatedDate = moment(date).format("YYYY-MM-DD");
            forceChange(actualElement, formatedDate);
        },
    });
};

export const TimepickerInstance = (
    selector,
    obj = {
        twelveHour: false,
    }
) => {
    var timepickers = document.querySelectorAll(selector);
    M.Timepicker.init(timepickers, {
        ...obj,
        onCloseStart: (e) => {
            nextElementHourInput(e, "input");
        },
    });
};

export const initiateInstaces = () => {
    FormSelectInstance(".formBuilded select");
    DatepickerInstace(".formBuilded .datepicker");
    TimepickerInstance(".formBuilded .timepicker");
};
