import React from "react";

export default function Checkbox({ name, props }) {
    return (
        <div
            className={
                "row form-checkbox form-" +
                props.name.toLowerCase().replace(["*", "_"], "", "-")
            }
            key={name}
        >
            <label>{!props.hide && (props.label ?? name)}</label>
            <div
                className="input-field col s12"
                style={{ display: "flex", flexWrap: "wrap" }}
            >
                {props.objects.map((checkbox, key) => {
                    let prop = {
                        type: "checkbox",
                        id: checkbox.id ?? checkbox.label,
                        checked: checkbox.checked ?? false,
                        value: checkbox.value,
                        ...(checkbox.aditionalProps ?? {}),
                    };

                    prop["data-parent"] = name;
                    prop["data-keyarray"] = key;
                    return (
                        <p key={key}>
                            <label key={key}>
                                <input
                                    {...prop}
                                    onChange={() => {
                                        checkbox.checked = !checkbox.checked;
                                    }}
                                />
                                <span>{checkbox.label}</span>
                            </label>
                        </p>
                    );
                })}
            </div>
        </div>
    );
}
