import React from "react";
import { useLocation } from "react-router";
import Footer from "./componentes/Footer";
import Header from "./componentes/Header";

export default function Layout(props) {
    let Location = useLocation();
    console.log(Location);
    return (
        <div className={Location.pathname.split("/")[1]}>
            <Header />
            <div id="contentBody">{props.children}</div>
            <Footer />
        </div>
    );
}
