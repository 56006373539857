import React, { useEffect, useState } from "react";
import "nouislider";

function Range({ name, props, onChangeHandler = undefined }) {
    const [prop, setProps] = useState({});

    useEffect(() => {
        const newProps = {
            id: props.id ?? name,
            value: props.value ?? "",
            type: "range",
            min: props.min,
            max: props.max,
        };

        if (props.group) {
            newProps["data-group"] = props.group;
        }

        if (onChangeHandler) {
            newProps.onChange = onChangeHandler;
        }
        setProps({ ...newProps });
    }, [props]);

    return (
        <div
            className={
                "row form-range form-" +
                prop.id.toLowerCase().replace(["*", "_"], "", "-")
            }
        >
            <p>
                <b>Valor : {prop.value}</b>
            </p>
            <div
                className="input-field col s12"
                style={{
                    display: "flex",
                    flexWrap: "nowrap",
                    justifyContent: "space-evenly",
                }}
            >
                <p style={{ padding: "5px" }}>
                    <label htmlFor={prop.id}>{prop.min}</label>
                </p>
                <p className="range-field" style={{ width: "90%" }}>
                    <input {...prop} />
                </p>
                <p style={{ padding: "5px" }}>
                    <label htmlFor={prop.id}>{prop.max}</label>
                </p>
            </div>
        </div>
    );
}

export default Range;
