import React, { useEffect } from "react";
import { forceChange } from "./instances";

export default function Select({ name, props, handler = undefined }) {
    const prop = {
        className:
            (props.className ? props.className + " " : "") +
            (props.icons ? "icons " : "") +
            (props.required ? "required " : ""),
        id: props.id ?? name,
        value: props.value ?? "",
    };

    if (handler) {
        prop.onChange = handler;
    }

    if (props.multiple) {
        prop.value = prop.value === "" ? [] : prop.value;
        prop.multiple = true;
    }

    if (props.group) {
        prop["data-group"] = props.group;
    }

    const icons = props.icons ?? [];
    const dummy = props.nodummy
        ? {}
        : {
              "": props.noSpan
                  ? name
                  : props.dummy
                  ? props.dummy
                  : "Seleccione un elemento",
          };
    const values = {
        ...dummy,
        ...props.values,
    };
    if (props.noempty) {
        delete values[""];
    }

    const handleChange = (e) => {
        let value = [];
        let options = e.target.options;
        for (let i = 0; i < options.length; i++) {
            if (options[i].selected === true) {
                value.push(options[i].value);
            }
        }

        props.value = value;
    };

    const renderOptions = (label, option, icon = icons) => {
        if (typeof label === "object") {
            return (
                <optgroup label={option} key={option + "key"}>
                    {Object.entries(label).map(([id, label]) =>
                        renderOptions(label, id)
                    )}
                </optgroup>
            );
        } else {
            let attr = {
                value: option,
                key: option + "key",
            };

            if (typeof icon[option] !== "undefined") {
                attr["data-icon"] = icon[option];
            }

            if (props.disableOptions && props.disableOptions.includes(option)) {
                attr["disabled"] = true;
            }

            return <option {...attr}>{label}</option>;
        }
    };

    useEffect(() => {
        props.value = [document.getElementById(prop.id).value];
    }, []);

    return (
        <div
            className={
                "row form-select form-" +
                prop.id.toLowerCase().replace(["*", "_"], "", "-")
            }
            key={prop.id}
        >
            <div className="input-field col s12">
                {!props.noSpan && <span>{props.label ?? name}</span>}
                <select {...prop} onChange={handleChange}>
                    {Object.entries(values).map(([id, label]) =>
                        renderOptions(label, id)
                    )}
                </select>
            </div>
        </div>
    );
}
