import "./App.css";
import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Index from "./componentes/Index";
import Restaurant from "./componentes/Restaurant";
import Layout from "./Layout";
import axios from "axios";
import ConfirmReserva from "./componentes/ConfirmReserva";
import Reservado from "./componentes/Reservado";
import RestaurantConfirm from "./componentes/RestaurantConfirm";
//test de app password
function App() {
    const [isSet, setSet] = useState(false);
    useEffect(() => {
        axios.defaults.baseURL =
            "https://reservas.casaseat.relevance.pro/reservas-backend/public";
        setSet(true);
    }, []);
    if (!isSet) {
        return "...";
    }
    return (
        <BrowserRouter>
            <Layout>
                <Suspense>
                    <Switch>
                        <Route
                            path="/coworking"
                            exact
                            component={Index}
                        ></Route>
                        <Route
                            path="/confirm/reserva/:token"
                            component={ConfirmReserva}
                        ></Route>
                        <Route
                            path="/coworking/registred/:id"
                            component={Reservado}
                        ></Route>
                        <Route component={() => <Redirect to="/coworking" />} />
                    </Switch>
                </Suspense>
            </Layout>
        </BrowserRouter>
    );
}

export default App;
