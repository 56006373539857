import axios from "axios";
import React, { useEffect, useState } from "react";
import Form from "../Formbuilder/Form";
import M from "materialize-css";
import { Redirect } from "react-router";
import moment from "moment";

let previus;

const dynamic_dataLayer = window.dynamic_dataLayer;

const SITE_KEY = "6LfBvgceAAAAANH217N-USau34vAyZvxOn94G579";

export default function RestaurantForm(props) {
    const [data, setData] = useState(undefined);

    const [redirect, setRedirect] = useState(undefined);

    const fetchData = async () => {
        const response = await axios.post("/form/build", {
            form: "Restaurant",
            action: "create",
        });

        handleChange(response.data);
    };

    const validatePersons = async () => {
        if (!data) {
            return;
        }

        if (
            data.Fecha.childs["fecha_inicio"].value == "" ||
            !data.Fecha.childs.Fecha2.childs["hora_entrada"].value
        ) {
            M.toast({
                html: "Fecha y hora necesarias para validar plazas disponibles",
                classes: "white-text red",
            });
            return;
        }
        const response = await axios.post("/restaurant/disponibles", {
            Fecha: data.Fecha,
        });

        if (response.data.error) {
            M.toast({ html: response.data.error, classes: "red white-text" });
            return;
        }
        if (response.data < previus) {
            M.toast({
                html:
                    "No contamos con disponibilidad para " +
                    previus +
                    " personas. Quedan " +
                    response.data +
                    " disponibles",
                classes: "white-text red",
            });
        }
    };

    const handleChange = async (e) => {
        if (
            e.Fecha.childs["fecha_inicio"].value.includes(
                moment().format("YYYY-MM-DD")
            )
        ) {
            e.Fecha.childs.Fecha2.childs["hora_entrada"].disableOptions =
                Object.keys(
                    e.Fecha.childs.Fecha2.childs["hora_entrada"].values
                ).filter((e) => e <= moment().format("HH:mm"));
        } else {
            delete e.Fecha.childs.Fecha2.childs["hora_entrada"].disableOptions;
        }
        if (e.Fecha.childs.Fecha2.childs["num_clientes"].value[0] != previus) {
            previus = e.Fecha.childs.Fecha2.childs["num_clientes"].value[0];
            validatePersons();
        }
        setData(e);
    };

    const handleSubmit = async (e) => {
        const button = document.querySelector('[type="submit"]');

        button.setAttribute("disabled", true);

        try {
            window.grecaptcha.ready(() => {
                window.grecaptcha
                    .execute(SITE_KEY, { action: "submit" })
                    .then(async (token) => {
                        const response = await axios.post(
                            "/restaurant/create",
                            { ...data, "g-recaptcha-response": token }
                        );

                        if (response.data.error) {
                            M.toast({
                                html: response.data.error,
                                classes: "red white-text",
                            });
                            dynamic_dataLayer.push("errorForm", {
                                eventName: "lastStep",
                                kpiForm: "reserveRestaurant",
                                errorType: response.status,
                            });
                            button.removeAttribute("disabled");
                            return;
                        }
                        M.toast({
                            html: "Reserva creada con exito",
                            classes: "green white-text",
                        });
                        dynamic_dataLayer.push("newForm", {
                            eventName: "lastStep",
                            kpiForm: "reserveRestaurant",
                        });
                        setRedirect(response.data);
                    });
            });
        } catch (error) {
            dynamic_dataLayer.push("errorForm", {
                eventName: "lastStep",
                kpiForm: "reserveRestaurant",
                errorType: error.response?.status ?? "SERVER",
            });
            button.removeAttribute("disabled");
        }
    };

    useEffect(() => {
        fetchData();
        const loadScriptByURL = (id, url, callback) => {
            const isScriptExist = document.getElementById(id);

            if (!isScriptExist) {
                var script = document.createElement("script");
                script.type = "text/javascript";
                script.src = url;
                script.id = id;
                script.onload = function () {
                    if (callback) callback();
                };
                document.body.appendChild(script);
            }

            if (isScriptExist && callback) callback();
        };

        loadScriptByURL(
            "recaptcha-key",
            "https://www.google.com/recaptcha/api.js?render=" + SITE_KEY,
            null
        );
    }, []);

    if (redirect) {
        return <Redirect push={true} to={"/restaurante/confirm/" + redirect} />;
    }

    if (!data) {
        return <p>Loading ...</p>;
    }

    return (
        <Form
            data={data}
            onChange={handleChange}
            onSubmit={handleSubmit}
            submitText="Reservar"
        />
    );
}
