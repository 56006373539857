import React from "react";

export default function Datepicker({ name, props, handler = undefined }) {
    const prop = {
        id: props.id ?? name,
        className: (props.className ? props.className : "") + " datepicker ",
        placeholder: props.placeholder,
        value: props.value ?? "",
        type: "text",
    };

    if (props.required === true) {
        prop.required = true;
    }

    if (handler) {
        prop.onChange = handler;
    }

    if (props.group) {
        prop["data-group"] = props.group;
    }

    return (
        <div
            className={
                "row form-datepicker form-" +
                prop.id.toLowerCase().replace(["*", "_"], "", "-")
            }
            key={prop.id}
        >
            <div className="input-field col s12">
                {props.icon &&
                    (props.icon.custom ? (
                        <i className={props.icon.class}></i>
                    ) : (
                        <i
                            className="material-icons prefix"
                            style={{ color: props.iconColor }}
                        >
                            {props.icon}
                        </i>
                    ))}
                <input
                    {...prop}
                    onChange={(e) => {
                        props.value = e.target.value;
                    }}
                />
                <label htmlFor={prop.id} className={prop.value && "active"}>
                    {props.label ?? name}
                </label>
            </div>
        </div>
    );
}
