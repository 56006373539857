import React, { useEffect } from "react";
import NetworkingForm from "./NetworkingForm";
import Banner from "../img/theme/header.jpg";
import simpleParallax from "simple-parallax-js";

export default function Index(props) {
    useEffect(() => {
        document.querySelector("title").innerText =
            "Reservas Coworking | CASA SEAT";
        const image = document.querySelector(".parallax");
        new simpleParallax(image, {
            scale: 1.5,
        });
    }, []);
    return (
        <>
            {/* <div
                className="header-banner"
                style={{
                    backgroundImage: `url(${Banner})`,
                }}
            ></div> */}
            <img src={Banner} className="parallax" />
            <div className="container coworking">
                <div className="intro-text">
                    <h3>
                        Reserva tu plaza <br></br>
                        en la zona de coworking de CASA SEAT.
                    </h3>
                    <h4>
                        Cumplimenta este formulario <br></br> para reservar tu
                        espacio coworking.
                    </h4>
                    <p>
                        El tiempo máximo de reserva son dos horas. Es posible
                        extenderla siempre y cuando no haya reservas
                        posteriores. Cada reserva corresponde a una plaza. Si 10
                        minutos después de la hora de tu reserva no has podido
                        venir, la plaza se liberará para otros posibles
                        usuarios. Además, CASA SEAT se reserva el derecho de
                        cancelar la reserva por motivos de agenda.
                    </p>
                </div>
                <NetworkingForm></NetworkingForm>
                <div className="content-extra">
                    <div className="content-legal">
                        <span>Responsable del tratamiento:</span> SEAT, S.A.
                        <br></br>
                        <span>Finalidades:</span> Reservar el espacio coworking
                        y llevar a cabo las gestiones necesarias derivadas del
                        mismo. En caso de que usted lo haya consentido, enviarle
                        comunicaciones de interés sobre CASA SEAT, tanto por
                        medios escritos como electrónicos y/o telefónicos.
                        <br></br>
                        <span>Destinatarios:</span> Sus datos no serán cedida a
                        terceros, excepto para el cumplimiento de obligaciones
                        legales.<br></br>
                        <span>Legitimación:</span> Consentimiento del
                        interesado.<br></br>
                        <span>Derechos de los interesados:</span> Tiene derecho
                        a retirar sus consentimientos en cualquier momento, a
                        oponerse al tratamiento, a acceder, rectificar y
                        suprimir los datos, así como otros derechos detallados
                        en la Información adicional, mediante solicitud escrita
                        dirigida a SEAT, S.A, CASA SEAT, División SEAT España -
                        Departamento Atención al Cliente, Autovía A-2, Km. 585,
                        Martorell (Barcelona) o bien remitiendo un correo
                        electrónico a{" "}
                        <a href="mailto:privacidad@seat.es" target="_blank">
                            privacidad@seat.es
                        </a>
                        .<br></br>
                        <span>Información adicional:</span> Puede consultar la
                        información adicional y detallada sobre protección de
                        datos en nuestra{" "}
                        <a
                            href="https://www.casa.seat/es/legal/politica-privacidad.html"
                            target="_blank"
                        >
                            Política de Privacidad.
                        </a>
                        <br></br>
                    </div>
                </div>
            </div>
        </>
    );
}
