import React from "react";

export default function Radio({ name, props }) {
    const handleChange = (e) => {
        const key = e.target.dataset.keyarray;

        props.objects.forEach((element, keyarray) => {
            if (keyarray == key) {
                props.objects[keyarray].checked = true;
            } else {
                props.objects[keyarray].checked = false;
            }
        });
    };

    return (
        <div
            className={
                "row form-radio form-" +
                props.name.toLowerCase().replace(["*", "_"], ["", "-"])
            }
            key={name}
        >
            <div className="input-field col s12">
                <span>{props.label ?? name}</span>
                {props.objects.map((checkbox, key) => {
                    let prop = {
                        type: "radio",
                        id: checkbox.id ?? checkbox.label,
                        checked: checkbox.checked ?? false,
                        value: checkbox.value,
                        name: props.name ?? name,
                        ...(checkbox.aditionalProps ?? {}),
                    };

                    if (props.required) {
                        prop["required"] = true;
                    }

                    prop["data-parent"] = name;
                    prop["data-keyarray"] = key;

                    return (
                        <p key={key}>
                            <label key={key}>
                                <input {...prop} onChange={handleChange} />
                                <span>{checkbox.label}</span>
                            </label>
                        </p>
                    );
                })}
            </div>
        </div>
    );
}
