import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Banner from "../img/theme/header.jpg";

export default function Reservado(props) {
    const [reserva, setReserva] = useState(undefined);

    const fetchReserva = async () => {
        try {
            const response = await axios.get(
                "/network/reserva/" + props.match.params.id
            );

            setReserva(response.data);
        } catch (error) {}
    };

    useEffect(() => {
        fetchReserva();
        document.querySelector("title").innerText =
            "Reservas Coworking | CASA SEAT";
    }, []);

    if (!reserva) {
        return "Loading ...";
    }

    return (
        <>
            <div
                className="header-banner"
                style={{
                    backgroundImage: `url(${Banner})`,
                }}
            ></div>
            <div className="container intro-text" style={{ marginTop: "20px" }}>
                <h3>Reserva Confirmada</h3>

                <p>
                    <br />
                    Confirmamos tu reserva en el espacio de coworking para el
                    día :<br />
                    <b>
                        {reserva.fecha_natural} de{" "}
                        {moment(reserva.fecha_inicio).format("HH:mm")}h a{" "}
                        {moment(reserva.fecha_fin).format("HH:mm")}h.
                    </b>
                    <br />
                    Te recordamos que una vez finalizada la reserva, cabe la
                    posibilidad de extenderla dos horas más de forma
                    consecutiva, siempre y cuando haya disponibilidad en dicha
                    franja horaria.
                </p>
                <p>
                    <br />
                    Si finalmente no pudieras acudir a la reserva, puedes
                    anularla a través de esta dirección de correo electrónico:{" "}
                    <a
                        href="mailto:coworking.casaseat@seat.es"
                        className="black-text"
                        style={{
                            textDecoration: "underline",
                        }}
                    >
                        coworking.casaseat@seat.es
                    </a>
                </p>
            </div>
        </>
    );
}
