import React from "react";

export default function Text({ name, props, handler = undefined }) {
    const prop = {
        id: props.id ?? name,
        className: props.validate ? "validate " : "",
        required: true,
        placeholder: props.placeholder,
        pattern: props.pattern,
        disabled: true,
        value: props.value ?? "",
        type: props.customType ?? "text",
        autoComplete: "new-password",
    };

    if (handler) {
        prop.onChange = handler;
    }

    if (props.group) {
        prop["data-group"] = props.group;
    }

    prop["data-tooltip"] = props.help ?? "";

    if (!props.disabled) {
        delete prop.disabled;
    }

    if (!props.required) {
        delete prop.required;
    }

    return (
        <div
            className={
                "row form-text form-" +
                prop.id.toLowerCase().replace(["*", "_"], "", "-")
            }
            style={{ marginBottom: "unset" }}
            key={prop.id}
        >
            <div className="input-field col s12">
                {props.icon &&
                    (props.icon.custom ? (
                        <i className={props.icon.class}></i>
                    ) : (
                        <i
                            className="material-icons prefix"
                            style={{ color: props.iconColor }}
                        >
                            {props.icon}
                        </i>
                    ))}
                <input
                    {...prop}
                    onChange={(e) => {
                        props.value = e.target.value;
                    }}
                />
                <label htmlFor={prop.id} className={prop.value && "active"}>
                    {props.label ?? name}
                    {props.customType == "hidden" && " : " + prop.value}
                </label>
                <span
                    className="helper-text"
                    data-error={props.errorMessage ?? "incorrecto"}
                    data-success={props.successMessage ?? "correcto"}
                >
                    {props.help ?? ""}
                </span>
            </div>
        </div>
    );
}
